@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

body {
  font-size: 16px;
  line-height: 1.3;
  list-style-type: none;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
}

a,
button {
  background: none;
  border: none;
  text-decoration: none;
  cursor: pointer;
  -webkit-text-decoration-skip: objects;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

ul,
ol {
  list-style-type: none;
  padding: 0;
}
